<template>
    <PageComponent 
        :header="title"
        :header-type="'ingredient'">

        <!-- Nav -->
        <IngredientNav
            :title="title" 
            :ancestors="ancestors" />

        <!-- Meta -->
        <IngredientMeta
            :metadata="{
                relatedIngredients,
                otherNames,
                ratio,
                affiliates,
            }" />

        <!-- Description -->
        <IngredientDescription 
            v-if="wikiDescription || wikiLink"
            :description="wikiDescription"
            :link="wikiLink" />

        <!-- Related Recipes -->
        <PageSection 
            v-if="relatedRecipes.length"
            title="Related Recipes">
            <RecipeList :entries="relatedRecipes" />
        </PageSection>
        

    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import IngredientNav from '@/components/ingredient/IngredientNav.vue';
import IngredientMeta from '@/components/ingredient/IngredientMeta.vue';
import IngredientDescription from '@/components/ingredient/IngredientDescription.vue';
import RecipeList from '@/components/recipe/RecipeList.vue';
import PageSection from "@/components/PageSection.vue";
import { mapState, mapActions } from 'pinia';
import { useIngredientsStore } from '@/stores/ingredients';

export default {
    name: 'IngredientEntryView',
    components: {
        PageComponent,
        IngredientNav,
        IngredientMeta,
        IngredientDescription,
        RecipeList,
        PageSection,
    },
    data() {
        return {
            title: null,
            id: null,
            ancestors: null,
            wikiDescription: null,
            wikiLink: null,
            relatedIngredients: null,
            otherNames: null,
            ratio: null,
            affiliates: null,
            relatedRecipes: [],
        };
    },
    computed: {

        /**
         * Store Getters
         */
        ...mapState(useIngredientsStore, [
            'getIngredientBySlug',
            'getIngredientColorById'
        ]),
    },
    async created() {
        await this.fetchState();
        await this.fetchData();
        this.setColor();
        console.log(this.wikiDescription);
    },
    beforeUnmount() {
        this.removeColor();
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useIngredientsStore, [
            'fetchState',
        ]),

        /**
         * Fetch entry data
         */
        async fetchData() {
            const ingredientData = await this.getIngredientBySlug(this.$route.params.slug);
            Object.assign(this, ingredientData);
        },

        /**
         * Set background color
         */
        setColor() {
            document.documentElement.style.backgroundColor = this.getIngredientColorById(this.id);
        },

        /**
         * Remove background color
         */
         removeColor() {
            document.documentElement.style.backgroundColor = '';
        },
    },
};
</script>