import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import axios from "axios"

export const useAboutStore = defineStore('about', {
    state: () => ({
        header: '', 
        bodyText: '',
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            const response = await axios.post(this.apiUrl, {
                query: `{
                    entry (section: "about") {
                        title
                        ... on about_about_Entry {
                            header
                            bodyText
                        }
                    }
                }`,
            });
            const entry = response.data.data.entry;
            this.header = entry.header;
            this.bodyText = entry.bodyText;
        }
    }
})
