<template>
    <ul class="FeaturedList">
        <li 
            v-for="(entry, i) in entries"
            :key="entry.id"
            class="FeaturedList-item">
            <header class="FeaturedList-itemHeader">
                <p>
                    Menu № {{ entryCount - i }}
                </p>
                <h2
                    class="FeaturedList-itemTitle">
                    <p>{{ entry.title }}</p>
                </h2>
            </header>
            <router-link 
                :to="`/menus/${entry.slug}`"
                class="FeaturedList-itemLink">
                View the menu
            </router-link>
            <div class="FeaturedList-itemBody">
                <RecipeList 
                    :entries="entry.relatedRecipes" />
            </div>
        </li>
    </ul>
</template>

<script>
import RecipeList from '@/components/recipe/RecipeList.vue'

export default {
    name: 'FeaturedList',
    components: {
        RecipeList,
    },
    props: {
        entryCount: {
            type: Number,
            default: () => (0),
        },
        entries: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>

<style>
.FeaturedList-item {
    margin: var(--space-sm) 0;
    text-align: center;
}
.FeaturedList-itemHeader {
    text-align: center;
    font: var(--font-md);
    margin: var(--space-sm) 0;
}
.FeaturedList-itemTitle {
    margin: var(--space-sm) 0;
}
.FeaturedList-itemLink {
    text-decoration: underline;
}
</style>