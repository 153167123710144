<template>
    <div class="Ad">
        <figure class="Ad-figure">
            <PatternComponent />
            <a 
                v-if="randomAdvertisement"
                class="Ad-link"
                :href="randomAdvertisement.adUrl"
                target="blank">
                <h4 class="Ad-text">
                    {{ randomAdvertisement.adText }}
                </h4>
            </a>
            
        </figure>
        <figcaption class="Ad-caption">
            Advertise with No Menu
        </figcaption>
    </div>
</template>

<script>
import PatternComponent from '@/components/PatternComponent.vue';
import { mapState, mapActions } from 'pinia';
import { useAdStore } from '@/stores/ad';

export default {
    name: "AdComponent",
    components: {
        PatternComponent,
    },
    computed: {

        /**
         * State
         */
        ...mapState(useAdStore, [
            'advertisements',
        ]),

        /**
         * Random Advertisment
         */
        randomAdvertisement() {
            return (this.advertisements.length)
                ? this.advertisements[Math.floor(Math.random() * this.advertisements.length)]
                : null;
        }
    },
    created() {
        this.fetchState()
    },
    methods: {

        /**
         * Store Actions
         */
         ...mapActions(useAdStore, [
            'fetchState'
        ]),
    }
};
</script>

<style>
.Ad-figure {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.Ad-link {
    position: relative;
    padding: var(--space-lg) 0;
}
.Ad-caption {
    font: var(--font-sm);
    margin-top: var(--space-xs);
}
.Ad-text {
    font: var(--font-lg);
}
</style>