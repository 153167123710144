<template>
    <PageComponent 
        :header="header"
        :header-type="'recipe'">
        
        <!-- Meta -->
        <PageSection title="Basic Information">
            <RecipeMeta 
                :metadata="{
                    recipeAuthor,
                    recipeSource,
                    recipeAdaptedBy,
                    prepTime,
                    yields,
                    cuisines,
                    dietaryConsiderations,
                    cookingMethod,
                    mealTypes,
                    specialEquipment,
                    summary,
                }" />
        </PageSection>

        <!-- Ingredients -->
        <PageSection 
            title="Ingredients and Preparation" 
            class-name="PageSection--recipePrep">
            <RecipeIngredients :ingredients="ingredients" />
            <RecipeInstructions :instructions="instructions" />
        </PageSection>

        <!-- Related Recipes -->
        <PageSection 
            v-if="relatedRecipes.length"
            title="Related Recipes">
            <RecipeList :entries="relatedRecipes" />
        </PageSection>

    </PageComponent>
</template>

<script>
import PageComponent from "@/components/PageComponent.vue";
import PageSection from "@/components/PageSection.vue";
import RecipeMeta from "@/components/recipe/RecipeMeta.vue";
import RecipeIngredients from "@/components/recipe/RecipeIngredients.vue";
import RecipeInstructions from "@/components/recipe/RecipeInstructions.vue";
import RecipeList from '@/components/recipe/RecipeList.vue';
import { mapState } from 'pinia'
import { useRecipesStore } from '@/stores/recipes'

export default {
    name: "RecipeView",
    components: {
        PageComponent,
        PageSection,
        RecipeMeta,
        RecipeIngredients,
        RecipeInstructions,
        RecipeList,
    },
    data() {
        return {
            title: "",
            recipeAuthor: [],
            recipeSource: {},
            recipeAdaptedBy: {},
            prepTime: [],
            yields: "",
            cookingMethod: [],
            cuisines: [],
            dietaryConsiderations: [],
            mealTypes: [],
            specialEquipment: [],
            summary: "",
            ingredients: [],
            instructions: [],
            relatedRecipes: [],
        };
    },
    computed: {

        /**
         * Store Getters
         */
        ...mapState(useRecipesStore, [
            'getRecipeBySlug',
        ]),

        /**
         * Header
         * @returns {string}
         */
        header() {
            return (this.recipeAuthor.length)
                ? `${ this.title } \n by ${ this.commaSeperateList(this.recipeAuthor) }`
                : this.title;
        },
    },
    async created() {
        await this.fetchData();
    },
    methods: {

        /**
         * Fetch entry data
         */
        async fetchData() {
            const recipeData = await this.getRecipeBySlug(this.$route.params.slug);
            Object.assign(this, recipeData);
        },

        /**
         * Comma separate list
         * @param {Array} array 
         */
        commaSeperateList(array) {
            return array.reduce(
                (a,b,i) => {
                    return (i > 0) 
                        ? (i == array.length - 1)
                            ? a + ' and ' + b.name
                            : a + ', ' + b.name
                        : b.name;
                },
                array[0].name,
            );
        }
    },
};
</script>

<style>
</style>