import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import { useRecipesStore } from './recipes'
import axios from "axios"

export const useMenusStore = defineStore('menus', {
    state: () => ({
        entryCount: 0, 
        entries: [],
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },

        /**
         * Get Menu By Slug
         * Performs GraphQL query and returns the object
         * @param {string} slug
         * @returns {Object}
         */
        getMenuBySlug() {
            return async (slug) => {
                const response = await axios.post(this.apiUrl, {
                    query: `{
                        entry(section: "menus", slug: "${ slug }") {
                            title
                            ... on menus_default_Entry {
                                relatedRecipes {
                                    id
                                }
                                guestName {
                                    firstName
                                    lastName
                                }
                                body {
                                    ... on body_bodyText_BlockType {
                                        text
                                        typeHandle
                                        id
                                    }
                                    ... on body_interviewText_BlockType {
                                        attribution
                                        text
                                        typeHandle
                                        id
                                    }   
                                }
                            }
                        }
                    }`,
                });
                const recipes = useRecipesStore();
                await recipes.fetchState();
                const entry = response.data.data.entry;
                const relatedRecipes = entry.relatedRecipes
                    .map((relatedRecipesEntry) => {
                        return recipes.entries.find((e) => relatedRecipesEntry.id == e.id);
                    })
                return {
                    ...entry,
                    guestName: (entry.guestName) ? entry.guestName[0] : null,
                    relatedRecipes,
                };
            };
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            const recipes = useRecipesStore();
            await recipes.fetchState();
            const response = await axios.post(this.apiUrl, {
                query: `{
                    entryCount(section: "menus")
                    entries (section: "menus") {
                        title
                        slug
                        id
                        ... on menus_default_Entry {
                            relatedRecipes {
                                id
                            }
                        }
                    }
                }`,
            });
            const entryCount = response.data.data.entryCount;
            const entries = response.data.data.entries
                .map((entry) => {
                    const firstRecipe = entry.relatedRecipes?.[0];
                    const firstRecipeColor = recipes.entries
                        .find((entry) => entry.id == firstRecipe?.id)?.color;
                    return {
                        ...entry,
                        color: firstRecipeColor,
                    }
                });
            this.entryCount = entryCount;
            this.entries = entries;
        }
    }
})
