import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import axios from "axios"

export const useAdStore = defineStore('ad', {
    state: () => ({
        advertisements: [],
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            if (!this.advertisements.length) {
                const response = await axios.post(this.apiUrl, {
                    query: `{
                        globalSet(handle: "global") {
                            ... on global_GlobalSet {
                                advertisements {
                                    ... on advertisements_advertisement_BlockType {
                                        adText
                                        adUrl
                                    }
                                }
                            }
                        }
                    }`,
                });
                const advertisements = response.data.data.globalSet.advertisements
                this.advertisements = advertisements;
            }
        },
    },
});