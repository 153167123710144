<template>
    <dl class="InterviewText">
        <template
            v-for="block in blocks"
            :key="block.id">
            <dt class="InterviewText-attribution">
                {{ block.attribution }}
            </dt>
            <dd 
                class="InterviewText-text" 
                v-html="block.text" />
        </template>
    </dl>
</template>

<script>
export default {
    name: "InterviewText",
    props: {
        blocks: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style>
.InterviewText {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0 var(--space-md);
}
.InterviewText p {
    margin: var(--space-sm) 0;
}
.InterviewText p:first-child {
    margin-top: 0;
}
</style>