<template>
    <div class="RecipeList">
        <div 
            v-if="hasFilters"
            class="RecipeList-filterList">
            <DropdownInput
                v-for="(filter,i) in filters"
                :key="i"
                :label="filter.label"
                :name="filter.name"
                :options="filter.options"
                @value-change="activateFilter" />
        </div>
        <ul class="RecipeList-entryList">
            <li 
                v-for="entry in filteredEntries"
                :key="entry.id"
                class="RecipeList-entry u-border--recipe"
                :style="`--color: ${ entry.color }`">
                <router-link 
                    :to="`/recipes/${entry.slug}`"
                    class="RecipeList-link">
                    <span class="RecipeList-title">
                        {{ entry.title }}
                    </span>
                    <span 
                        v-if="entry.recipeAuthor"
                        class="RecipeList-attribution">
                        by {{ this.commaSeperateList(entry.recipeAuthor) }}
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
    
</template>

<script>
import DropdownInput from '@/components/DropdownInput.vue';

export default {
    name: 'RecipeList',
    components: {
        DropdownInput,
    },
    props: {
        entries: {
            type: Array,
            default: () => ([]),
        },
        hasFilters: {
            type: Boolean,
            default: () => (false),
        }
    },
    data() {
        return {
            activeFilters: [],
        }
    },
    computed: {

        /**
         * List filters
         */
        filters() {
            return [
                {
                    label: 'Cuisines',
                    name: 'cuisines',
                    options: this.optionsFromTagGroup('cuisines'),
                },
                {
                    label: 'Meal Types',
                    name: 'mealTypes',
                    options: this.optionsFromTagGroup('mealTypes'),
                },
                {
                    label: 'Dietary Considerations',
                    name: 'dietaryConsiderations',
                    options: this.optionsFromTagGroup('dietaryConsiderations'),
                },
            ]
        },

        /**
         * Entries after filters have been applied
         */
        filteredEntries() {
            return (this.activeFilters.length)
                ? this.entries.filter((entry) => {
                    return this.activeFilters.some((filter) => {
                        return entry[filter.name].some((tag) => {
                            return tag.id == filter.value;
                        });
                    });
                })
                : this.entries;
        }
    },
    methods: {

        /**
         * Format dropdown options given name of tag group
         * @param {string} tagGroupName 
         */
        optionsFromTagGroup(tagGroupName) {
            return this.entries
                .map((entry) => entry[tagGroupName])
                .reduce((tags,tag) => {
                    return (tag.length) 
                        ? [...tags, ...tag]
                        : tags;
                }, [])
                .reduce((tags,tag) => {
                    return (!tags.some((ref) => (ref.id == tag.id)))
                        ? [...tags, tag]
                        : tags;
                }, [])
                .map((tag) => {
                    return {
                        label: tag.title,
                        value: tag.id,
                    }
                });
        },

        /**
         * Activate filter chosen via dropdown
         * @param {Object} filter 
         */
        activateFilter(filter) {
            this.activeFilters = (filter.value != null)
                ? [...this.activeFilters.filter((f) => f.name !== filter.name),filter]
                : this.activeFilters.filter((f) => f.name !== filter.name);
        },

        /**
         * Comma separate list
         * @param {Array} array 
         */
        commaSeperateList(array) {
            return array.reduce(
                (a,b,i) => {
                    return (i > 0) 
                        ? (i == array.length - 1)
                            ? a + ' and ' + b.name
                            : a + ', ' + b.name
                        : b.name;
                },
                array[0].name,
            );
        }
    }
}
</script>

<style>
.RecipeList-filterList {
    display: flex;
    align-items: flex-start;
}
.RecipeList-entryList {
    margin: var(--space-sm) 0;
    text-align: left;
}
.RecipeList-entry {
    margin: var(--space-xs) 0;
}
.RecipeList-entry:hover {
    background-color: var(--color);
}
.RecipeList-link {
    display: block;
    padding: 0 var(--space-xs);
}
.RecipeList-attribution {
    color: var(--color-light-grey);
}
</style>