<template>
    <section 
        class="PageSection"
        :class="[
            { 'is-closed' : isClosed },
            className
        ]">

        <!-- Header -->
        <header 
            class="PageSection-header"
            @click="isClosed = !isClosed">
            <h2>{{ title }}</h2>
        </header>

        <!-- Body -->
        <div class="PageSection-body">
            <slot />
        </div>
        
    </section>
</template>

<script>
export default {
    name: "PageSection",
    props: {
        title: {
            type: String,
            default: () => (''),
        },
        className: {
            type: String,
            default: () => (null),
        },
    },
    data: () => ({
        isClosed: false,
    }),
}
</script>

<style>
.PageSection {
    margin: var(--space-sm) 0;
}
.PageSection-header {
    font: var(--font-md);
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: var(--space-md);
    margin-bottom: var(--space-sm);
}
.PageSection-header::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-bottom: 0.6em solid black;
}
.PageSection.is-closed .PageSection-header::after {
    border-top: 0.6em solid black;
    border-bottom: none;
}
.PageSection-body {
    display: block;
}
.PageSection.is-closed .PageSection-body {
    display: none;
}
.PageSection--recipePrep .PageSection-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-sm);
    align-items: start;
}
</style>