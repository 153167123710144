<template>
    <PageComponent 
        header="Every ingredient has been recorded and organized">

        <!-- Ingredient List -->
        <IngredientList
            v-if="entries.length"
            :entries="entries" />
        
    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import IngredientList from '@/components/ingredient/IngredientList.vue';
import { mapState, mapActions } from 'pinia';
import { useIngredientsStore } from '@/stores/ingredients';

export default {
    name: 'IngredientDirectoryView',
    components: {
        PageComponent,
        IngredientList,
    },
    computed: {

        /**
         * State
         */
        ...mapState(useIngredientsStore, [
            'entries',
        ]),
    },
    created() {
        this.fetchState();
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useIngredientsStore, [
            'fetchState',
        ]),
    },
}
</script>