import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import axios from "axios"


export const useIngredientsStore = defineStore('ingredients', {
    state: () => ({
        entries: [],
        entriesFlattened: [],
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },

        /**
         * Get Ingredient By Slug
         * Performs GraphQL query and returns the object
         * @param {string} slug
         * @returns {Object}
         */
        getIngredientBySlug() {
            return async (slug) => {
                await this.fetchState();
                const entryResponse = await axios.post(this.apiUrl, {
                    query: `{
                        entry (section: "ingredients", slug: "${ slug }") {
                            title
                            id
                            typeHandle
                            ancestors (ancestorDist: 3) {
                                title
                                id
                                slug
                            }
                            ... on ingredients_ingredient_Entry {
                                otherNames {
                                    name
                                }
                                relatedIngredients {
                                    title
                                    slug
                                    id
                                }
                                ratio
                                wikiDescription
                                wikiLink
                                affiliates {
                                    affiliateName
                                    affiliateUrl
                                }
                            }
                        }
                    }`,
                });
                const entry = entryResponse.data.data.entry;
                const relatedEntriesResponse = await axios.post(this.apiUrl, {
                    query: `{
                        entries (section: "recipes", search: "ingredients:'${ entry.title }'") {
                            title
                            slug
                            id
                            ... on recipes_default_Entry {
                                recipeAuthor {
                                    name
                                    url
                                }
                            }
                        }
                    }`,
                })
                const relatedEntries = relatedEntriesResponse.data.data.entries;
                return {
                    ...entry,
                    relatedRecipes: relatedEntries,
                    color: this.entriesFlattened.find((ref) => ref.id == entry.id)?.color,
                }
            }
        },

        /**
         * Get Ingredient Color By Id
         * @param {string} id
         * @returns {string}
         */
        getIngredientColorById() {
            return (id) => {
                return this.entriesFlattened.find((entry) => entry.id == id)?.color;
            }
        },

        /**
         * Get Ingredient Type By Slug
         * @param {string} slug
         * @returns {string}
         */
        getIngredientTypeBySlug() {
            return (slug) => {
                return this.entriesFlattened.find((entry) => entry.slug == slug)?.typeHandle;
            }
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            if (!this.entries.length || !this.entriesFlattened.length) {
                const response = await axios.post(this.apiUrl, {
                    query: `{
                        entries (section: "ingredients", level: 1) {
                            ...IngredientFields
                            children {
                                ...IngredientFields
                                children {
                                    ...IngredientFields
                                    children {
                                        ...IngredientFields
                                        children {
                                            ...IngredientFields
                                            children {
                                                ...IngredientFields
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        entriesFlattened: entries (section: "ingredients") {
                            ...IngredientFields
                        }
                    }
                    fragment IngredientFields on EntryInterface {
                        title
                        id
                        slug
                        typeHandle
                        ... on ingredients_ingredient_Entry {
							color
                            otherNames {
                                name
                            }
                        }
                        ancestors {
                            ... on ingredients_ingredient_Entry {
                                color
                            }
                        }
                    }`,
                });
                const entries = response.data.data.entries;
                const entriesFlattened = response.data.data.entriesFlattened
                    .map((entry) => {
                        return {
                            ...entry,
                            color: (entry.ancestors.length)
                                ? entry.ancestors[0].color
                                : entry.color,
                        }
                    });
                this.entries = entries;
                this.entriesFlattened = entriesFlattened;
            }
        }
    },
});
