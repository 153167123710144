import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import { useIngredientsStore } from './ingredients'
import axios from "axios"

export const useSearchStore = defineStore('search', {
    state: () => ({
        availableTags: [],
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },

        /**
         * Get Query Tags from Query
         * @param {object} q
         * @returns {{ title: string, id: number }[]}
         */
        getQueryTagsFromQuery() {
            return (q) => {
                if (q.title && q.id) {
                    q.title = (typeof q.title == typeof '') ? [q.title] : q.title;
                    q.id = (typeof q.id == typeof '') ? [q.id] : q.id;
                    q.title.length = q.id.length = Math.min(q.title.length, q.id.length);
                    const queryTags = q.title.map((t, i) => ({ title: t, id: q.id[i] }));
                    return queryTags;
                } else {
                    return [];
                }
            }
        },

        /**
         * Get Results from Search String
         * Performs GraphQL query and returns the matching entries
         * @param {string} searchString
         * @returns {Array}
         */
        getResultsFromSearchString() {
            return async (searchString) => {
                await this.fetchState();
                const response = await axios.post(this.apiUrl, {
                    query: `{
                        entries (section: "recipes", search: "${ searchString }") {
                            title
                            slug
                            id
                            ... on recipes_default_Entry {
                                recipeAuthor {
                                    name
                                    url
                                }
                                cuisines {
                                    id
                                    title
                                }
                                mealTypes {
                                    id
                                    title
                                }
                                dietaryConsiderations {
                                    id
                                    title
                                }
                                ingredients {
                                    ... on ingredients_ingredient_BlockType {
                                        entry {
                                            title
                                            slug
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }`,
                });
                const ingredients = useIngredientsStore();
                await ingredients.fetchState();
                const entries = response.data.data.entries
                    .map((entry) => {
                        const firstIngredient = entry.ingredients?.[0].entry?.[0];
                        const firstIngredientColor = ingredients.entriesFlattened.find((entry) => entry.id == firstIngredient?.id)?.color;
                        return {
                            ...entry,
                            color: firstIngredientColor,
                        }
                    })
                    .sort((a,b) => a.title > b.title);
                return entries;
            };
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            if (!this.availableTags.length) {
                const response = await axios.post(this.apiUrl, {
                    query: `{
                        entries (section: "ingredients", type:"ingredient") {
                            title
                            id
                        }
                    }`,
                });
                const entries = response.data.data.entries;
                this.availableTags = entries;
            }
        },
    },
});