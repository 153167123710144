<template>
    <div 
        class="Dropdown"
        :class="{ 
            'is-open': isOpen,
            'is-active': isActive,
        }"
        @click="toggle">
        <div class="Dropdown-optionList">
            <div 
                class="Dropdown-option"
                :class="{ 'is-active': value == null }">
                {{ label }}
            </div>
            <div 
                v-for="(option,i) in options"
                :key="i"
                class="Dropdown-option"
                :class="{ 'is-active': value == option.value }"
                @click="updateValue(option.value)">
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownInput',
    props: {
        label: {
            type: String,
            default: () => (''),
        },
        name: {
            type: String,
            default: () => (''),
        },
        options: {
            type: Array,
            default: () => ([]),
        },
    },
    emits: ['valueChange'],
    data() {
        return {
            isOpen: false,
            value: null,
        }
    },
    computed: {

        /**
         * Is Active
         * @returns {boolean}
         */
        isActive() {
            return this.value != null;
        },
    },
    methods: {

        /**
         * Toggle
         */
        toggle() {
            if (this.isActive && !this.isOpen) {
                this.updateValue(null);
            } else {
                this.isOpen = !this.isOpen;
            }
        },

        /**
         * Update Value
         * @param {string} value 
         */
        updateValue(value) {
            this.value = value;
            this.$emit('valueChange', { 
                label: this.label, 
                name: this.name, 
                value: value,
            });
        }
    },
}
</script>

<style>
.Dropdown {
    margin-right: var(--space-md);
    width: max-content;
    display: flex;
    align-items: baseline;
    user-select: none;
    cursor: pointer;
}
.Dropdown::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    margin-left: var(--space-xs);
}
.Dropdown:not(.is-active):not(.is-open)::after {
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.6em solid black;
}
.Dropdown:not(.is-active).is-open::after {
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-bottom: 0.6em solid black;
}
.Dropdown.is-active::after {
    content: ' ×';
}
.Dropdown-option {
    width: max-content;
    display: none;
}
.Dropdown-option.is-active {
    display: block;
}
.Dropdown.is-open .Dropdown-option {
    display: block;
}
</style>