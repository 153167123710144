<template>
    <div class="RecipeIngredients">

        <!-- Unit Select -->
        <fieldset class="RecipeIngredients-unitSelect">
            <div>
                <input
                    id="imperial"
                    v-model="activeSystemOfMeasurement" 
                    class="RecipeIngredients-radio"
                    :value="'imperial'"
                    type="radio">
                <label for="imperial">Imperial</label>
            </div>
            <div>
                <input
                    id="metric"
                    v-model="activeSystemOfMeasurement"
                    class="RecipeIngredients-radio"
                    :value="'metric'"
                    type="radio">
                <label for="metric">Metric</label>
            </div>
        </fieldset>
        

        <template
            v-for="(chunk, i) in ingredientsChunked"
            :key="i">
            
            <!-- Ingredients -->
            <dl 
                v-if="chunk.typeHandle == 'ingredient'"
                class="RecipeIngredients-list">
                <template
                    v-for="ingredient in chunk.blocks"
                    :key="ingredient.id">
                    <router-link 
                        v-if="ingredient.entry && ingredient.entry.length"
                        :to="`/ingredients/${ ingredient.entry[0].slug }`"
                        class="RecipeIngredients-link"
                        :style="`--color: ${ getColorById(ingredient.entry[0].id) }`">
                        <dt 
                            class="RecipeIngredients-quantity u-border--ingredient">
                            {{ getMeasurementValue(ingredient) }}
                        </dt>
                        <dd 
                            class="RecipeIngredients-name u-border--ingredient">
                            <span>
                                {{ (ingredient.displayName) ? ingredient.displayName : ingredient.entry[0].title }}
                            </span>
                            <span v-if="ingredient.optional">
                                (optional)
                            </span>
                        </dd>
                    </router-link>
                </template>
            </dl>

            <!-- Separator -->
            <template
                v-else-if="chunk.typeHandle == 'separator'">
                <h3 
                    v-for="block in chunk.blocks"
                    :key="block.id"
                    class="RecipeIngredients-header">
                    {{ block.separatorText }}
                </h3>
            </template>
            
        </template>
    </div>
</template>

<script>
import { fraction, unit as mjsUnit, round } from 'mathjs';
import { mapState, mapActions } from 'pinia';
import { useIngredientsStore } from '@/stores/ingredients';

export default {
    name: 'RecipeIngredients',
    props: {
        ingredients: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            activeSystemOfMeasurement: 'imperial',
            units: {
                metric: {
                    'ml': { from: 'ml', to: 'tbsp' },
                    'l':  { from: 'l',  to: 'floz' },
                    'mg': { from: 'mg', to: 'oz' },
                    'g':  { from: 'g',  to: 'oz' },
                    'kg': { from: 'kg', to: 'lb' }
                },
                imperial: {
                    'tsp':  { from: 'teaspoon',   to:'ml' },
                    'tbsp': { from: 'tablespoon', to:'ml' },
                    'oz':   { from: 'oz',   to:'g' },
                    'lb':   { from: 'lb',   to:'kg' },
                    'cup':  { from: 'cup',  to:'ml' },
                    'pt':   { from: 'pt',   to:'l' },
                    'qt':   { from: 'qt',   to:'l' },
                    'floz': { from: 'floz', to:'l' },
                }
            }
        }
    },
    computed: {

        /**
         * State from store
         */
        ...mapState(useIngredientsStore, [
            'entriesFlattened',
        ]),

        /**
         * Ingredients grouped by type
         */
        ingredientsChunked() {
            const chunks = [];
            if (this.ingredients.length) {
                chunks[0] = {
                    typeHandle: this.ingredients[0].typeHandle,
                    blocks: [this.ingredients[0]]
                };
                this.ingredients.reduce((a,b) => {
                    if (a.typeHandle == b.typeHandle) {
                        chunks[chunks.length-1].blocks.push(b);
                    } else {
                        chunks.push({
                            typeHandle: b.typeHandle,
                            blocks: [b],
                        });
                    }
                    return b;
                });
            }
            return chunks;
        }
    },
    created() {
        this.fetchState();
    },
    methods: {

        /**
         * Actions from store
         */
        ...mapActions(useIngredientsStore, [
            'fetchState',
        ]),

        /**
         * Get Block Value
         * @param {Object} block 
         */
         getMeasurementValue(block) {
            const quantity = block.quantity;
            const unit = block.unit;

            if (quantity && unit) {
                const convertedQuantity = this.convertQuantity(quantity, unit, this.activeSystemOfMeasurement);  
                const intergerQuantity = this.getIntegerQuantity(convertedQuantity);
                const fractionString = this.getFractionString(convertedQuantity);
                const formattedQuantity = (this.activeSystemOfMeasurement == 'imperial')
                    ? `${(intergerQuantity || '')} ${(fractionString || '')}`
                    : convertedQuantity
                const convertedUnit = this.convertUnit(unit, this.activeSystemOfMeasurement);
                const formattedUnit = (convertedUnit != 'custom')
                    ? convertedUnit
                    : block.customUnit;
                return `${ formattedQuantity } ${ formattedUnit || '' }`;
            } else {
                return null;
            }            
        },

        /**
         * Convert Quantity
         * @param {number} quantity 
         * @param {string} unit 
         * @param {string} systemOfMeasurement 
         */
        convertQuantity(quantity, unit, systemOfMeasurement) {
            if (this.units.imperial[unit] && systemOfMeasurement == 'metric') {
                const imperialQuantity = mjsUnit(quantity, this.units.imperial[unit].from);
                const metricQuantity = round(imperialQuantity.to(this.units.imperial[unit].to).toNumber());
                return metricQuantity;
            } else if (this.units.metric[unit] && systemOfMeasurement == 'imperial') {
                const metricQuantity = mjsUnit(quantity, this.units.imperial[unit].from);
                const imperialQuantity = metricQuantity.to(this.units.metric[unit].to).toNumber();
                return imperialQuantity;
            } else {
                return quantity;
            }
        },

        /**
         * Convert Unit
         * @param {string} unit 
         * @param {string} systemOfMeasurement 
         */
        convertUnit(unit, systemOfMeasurement) {
            if (this.units.imperial[unit] && systemOfMeasurement == 'metric') {
                return this.units.imperial[unit].to;
            } else if (this.units.metric[unit] && systemOfMeasurement == 'imperial') {
                return this.units.metric[unit].to;
            } else {
                return unit;
            }
        },

        /**
         * Get Interger Quantity
         * @param {number} quantity 
         */
        getIntegerQuantity(quantity) {
            return (quantity) 
                ? Math.floor(quantity)
                : null;
        },

        /**
         * Get Fractional Quantity
         * @param {number} quantity 
         */
        getFractionalQuantity(quantity) {
            const intergerQuantity = this.getIntegerQuantity(quantity);
            return (quantity - intergerQuantity > 0)
                ? String(quantity).split('.')[1]
                : null;
        },

        /**
         * Get Fraction String
         * @param {number} quantity 
         */
        getFractionString(quantity) {
            const fractionalQuantity = this.getFractionalQuantity(quantity);
            return (fractionalQuantity)
                ? (1 % (1 % Number('0.' + fractionalQuantity))) 
                    ? fraction(`0.(${ fractionalQuantity })`).n + '/' + fraction(`0.(${ fractionalQuantity })`).d
                    : fraction(`0.${ fractionalQuantity }`).n + '/' + fraction(`0.${ fractionalQuantity }`).d
                : null;
        },

        /**
         * Get Color by ID
         * @param {string} id 
         */
        getColorById(id) {
            const entry = this.entriesFlattened.find((entry) => entry.id == id);
            return (entry) ? entry.color : null;
        },
    },
}
</script>

<style>
.RecipeIngredients {
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow: scroll;
}
.RecipeIngredients-list {
    display: grid;
    grid-template-columns: max-content 1fr;
}
.RecipeIngredients-item {
    display: contents;
}
.RecipeIngredients-quantity {
    flex: 0 0 150px;
    padding-right: var(--space-sm);
}
.RecipeIngredients-quantity:empty {
    padding-right: 0px;
}
.RecipeIngredients-header {
    margin-top: var(--space-sm);
}
.RecipeIngredients-link {
    display: contents;
}
.RecipeIngredients-link:not(:first-child) .RecipeIngredients-quantity,
.RecipeIngredients-link:not(:first-child) .RecipeIngredients-name {
    border-top: none;
}
.RecipeIngredients-link:hover > * {
    background-color: var(--color);
}
.RecipeIngredients-unitSelect {
    display: flex;
    gap: var(--space-sm);
    margin-bottom: var(--space-sm);
}
.RecipeIngredients-radio {
    display: none;
}
.RecipeIngredients-radio + label::before {
    content: '○ ';
    white-space: pre;
}
.RecipeIngredients-radio:checked + label::before {
    content: '● ';
    white-space: pre;
}
.RecipeIngredients-unitSelect label {
    cursor: pointer;
}
</style>