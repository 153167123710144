<template>
    <div class="RecipeMeta">
        <dl 
            class="RecipeMeta-list">

            <!-- Related Menus -->
            <MetaItem 
                v-if="(metadata.relatedMenus && metadata.relatedMenus.length)" 
                title="Menu">
                <template 
                    v-for="(menu, i) in metadata.relatedMenus"
                    :key="i">
                    <a :href="menu.url">
                        {{ menu.title }}
                    </a>
                    <span>
                        {{ formatSeparator(i, metadata.relatedMenus) }}
                    </span>
                </template> 
            </MetaItem>

            <!-- Recipe Author -->
            <MetaItem 
                v-if="metadata.recipeAuthor && metadata.recipeAuthor.length" 
                :title="(metadata.recipeAuthor.length > 1) ? 'Authors' : 'Author' ">
                <template
                    v-for="(author, i) in metadata.recipeAuthor"
                    :key="i">
                    <a 
                        v-if="author.url"
                        href="{{ author.url }}"
                        target="_blank">
                        {{ author.name }}
                    </a>
                    <span
                        v-else>
                        {{ author.name }}
                    </span>
                    <span>
                        {{ formatSeparator(i, metadata.recipeAuthor) }}
                    </span>
                </template>
            </MetaItem>

            <!-- Recipe Source -->
            <MetaItem 
                v-if="metadata.recipeSource && metadata.recipeSource.title" 
                title="Source">
                <a 
                    v-if="metadata.recipeSource.url"
                    href="{{ metadata.recipeSource.url }}"
                    target="_blank">
                    {{ metadata.recipeSource.title }}
                </a>
                <span
                    v-else>
                    {{ metadata.recipeSource.title }}
                </span>
            </MetaItem>

            <!-- Recipe Adapted By -->
            <MetaItem 
                v-if="metadata.recipeAdaptedBy && metadata.recipeAdaptedBy.name" 
                title="Adapted By">
                <a 
                    v-if="metadata.recipeAdaptedBy.url"
                    href="{{ metadata.recipeAdaptedBy.url }}"
                    target="_blank">
                    {{ metadata.recipeAdaptedBy.name }}
                </a>
                <span
                    v-else>
                    {{ metadata.recipeAdaptedBy.name }}
                </span>
            </MetaItem>

            <!-- Prep Time -->
            <MetaItem 
                v-if="metadata.prepTime && metadata.prepTime.length" 
                title="Time">
                <template 
                    v-for="(time, i) in metadata.prepTime"
                    :key="i">
                    <span>
                        {{ formatTime(time) }}
                    </span>
                    <span>
                        {{ formatSeparator(i, metadata.prepTime) }}
                    </span>
                </template> 
            </MetaItem>

            <!-- Yield -->
            <MetaItem 
                v-if="metadata.yield" 
                title="Yield">
                <span>
                    {{ metadata.yield }}
                </span>
            </MetaItem>

            <!-- Cuisine -->
            <MetaItem 
                v-if="metadata.cuisines && metadata.cuisines.length" 
                title="Cusine">
                <template 
                    v-for="(tag, i) in metadata.cuisines"
                    :key="i">
                    <a href="#">
                        {{ tag.title }}
                    </a>
                    <span>
                        {{ formatSeparator(i, metadata.cuisines) }}
                    </span>
                </template> 
            </MetaItem>

            <!-- Meal Type -->
            <MetaItem 
                v-if="metadata.mealTypes && metadata.mealTypes.length" 
                title="Meal Type">
                <template 
                    v-for="(tag, i) in metadata.mealTypes"
                    :key="i">
                    <a href="#">
                        {{ tag.title }}
                    </a>
                    <span>
                        {{ formatSeparator(i, metadata.mealTypes) }}
                    </span>
                </template>    
            </MetaItem>

            <!-- Dietary Considerations -->
            <MetaItem 
                v-if="metadata.dietaryConsiderations && metadata.dietaryConsiderations.length" 
                title="Dietary Considerations">
                <template 
                    v-for="(tag, i) in metadata.dietaryConsiderations"
                    :key="i">
                    <a href="#">
                        {{ tag.title }}
                    </a>
                    <span>
                        {{ formatSeparator(i, metadata.dietaryConsiderations) }}
                    </span>
                </template>
            </MetaItem>

            <!-- Cooking Method -->
            <MetaItem 
                v-if="metadata.cookingMethod && metadata.cookingMethod.length" 
                title="Cooking Method">
                <template 
                    v-for="(tag, i) in metadata.cookingMethod"
                    :key="i">
                    <a href="#">
                        {{ tag.title }}
                    </a>
                    <span>
                        {{ formatSeparator(i, metadata.cookingMethod) }}
                    </span>
                </template>
            </MetaItem>

            <!-- Special Equipment -->
            <MetaItem 
                v-if="metadata.specialEquipment && metadata.specialEquipment.length" 
                title="Special Equipment">
                <template 
                    v-for="(item, i) in metadata.specialEquipment"
                    :key="i">
                    <a href="#">
                        {{ item.title }}
                    </a>
                    <span>
                        {{ formatSeparator(i, metadata.specialEquipment) }}
                    </span>
                </template>
            </MetaItem>
        </dl>
        <div 
            v-if="metadata.summary"
            class="RecipeMeta-summary" 
            v-html="metadata.summary" />
    </div>
    
</template>

<script>
import MetaItem from '@/components/MetaItem.vue'

export default {
    name: "RecipeMeta",
    components: {
        MetaItem
    },
    props: {
        metadata: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {

        /**
         * Format Time
         * @param {Object} 
         */
        formatTime({ hours, minutes, note}) {
            return `${ (hours) ? hours + 'hr' : '' } ${ (minutes) ? minutes + 'm' : '' } ${ note }`
        },

        /**
         * Render a comma, if neccesary
         * @param {number} i 
         * @param {Array} array 
         */
        formatSeparator(i, array) {
            return (i < array.length - 1) ? ', ' : ''
        }
    }
}
</script>

<style>
.RecipeMeta-summary {
    margin: var(--space-sm) 0;
}
</style>