import { defineStore } from 'pinia'
import router from '../router'

export const useGlobalStore = defineStore('global', {
    getters: {

        /**
         * Get API URL based on route
         * (Craft live preview requires a token)
         * @returns {string}
         */
        apiUrl() {
            const route = router.currentRoute.value;
            return (route.query['x-craft-live-preview'])
                ? `${process.env.VUE_APP_API_URL}?token=${route.query['token']}`
                : process.env.VUE_APP_API_URL
        },
    },
});