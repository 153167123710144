<template>
    <ul 
        class="IngredientList"
        :class="{ 'is-open' : isOpen }">
        <li 
            v-for="entry in entriesSorted"
            :id="entry.slug"
            :key="entry.id"
            class="IngredientList-item"
            :class="{
                'u-border--ingredient' : entry.typeHandle == 'ingredient'
            }">
            <p 
                v-if="entry.typeHandle == 'ingredientGroup'"
                class="IngredientList-groupTitle"
                :class="{ 'is-open' : openChildren.includes(entry.slug) }"
                :style="`--color: ${ getIngredientColorById(entry.id) }`"
                @click.stop="toggleChild(entry.slug)">
                {{ entry.title }}
            </p>
            <p
                v-else-if="entry.typeHandle == 'ingredient'"
                class="IngredientList-ingredientTitle"
                :style="`--color: ${ getIngredientColorById(entry.id) }`">
                <router-link 
                    class="IngredientList-ingredientTitle"
                    :to="`/ingredients/${entry.slug}`">
                    {{ entry.title }}
                </router-link>
            </p>
            <IngredientList
                v-if="entry.children && entry.children.length"
                :entries="entry.children"
                :is-open="openChildren.includes(entry.slug)" />
        </li>
    </ul>
</template>

<script>
import { mapState } from 'pinia';
import { useIngredientsStore } from '@/stores/ingredients';

export default {
    name: 'IngredientList',
    props: {
        entries: {
            type: Array,
            default: () => [],
        },
        isOpen: {
            type: Boolean,
            default: () => true,
        },
    },
    data: () => ({
        openChildren: [],
        hash: null,
    }),
    computed: {

        /**
         * Store Getters
         */
        ...mapState(useIngredientsStore, [
            'getIngredientColorById',
        ]),

        /**
         * Entries Stored
         */
        entriesSorted() {
            const entries = this.entries
            return entries.sort((a,b) => {
                if (a.typeHandle !== b.typeHandle) {
                    if (a.typeHandle == 'ingredient' && b.typeHandle == 'ingredientGroup') {
                        return -1;
                    } else {
                        return 1;
                    }
                } else {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) {
                        return -1;
                    } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
                        return 1;
                    } else {
                        return 0;
                    }
                }                
            });
        }
    },
    mounted() {
        this.openTargetGroup()
        this.scrollToTarget()
    },
    methods: {

        /**
         * Toggle Child
         * @param {string} slug 
         */
        toggleChild(slug) {
            if (this.openChildren.includes(slug)) {
                this.openChildren = [];
            } else {
                this.openChildren = [slug];
            }
        },

        /**
         * Scroll To Target
         */
        scrollToTarget() {
            setTimeout(() => {
                if (this.entries.some(e => e.slug == this.$route.params.slug)) {
                    document.getElementById(this.$route.params.slug).scrollIntoView();
                }
            });
        },

        /**
         * Open Target Group
         */
        openTargetGroup() {
            if (this.$route.params.slug) {
                this.entries.forEach((entry) => {
                    this.openTargetGroupParent(entry, entry);
                });
            }
        },

        /**
         * Open Target Group Parent
         * @param {Object} parent 
         * @param {Object} child 
         */
        openTargetGroupParent(parent, child) {
            if (child.slug == this.$route.params.slug) {
                this.openChildren.push(parent.slug);
            }
            if (child.children) {
                child.children.forEach((child) => {
                    this.openTargetGroupParent(parent, child);
                });
            }
        },
    },
};
</script>

<style>
.IngredientList-item {
    display: none;
}
.IngredientList.is-open > .IngredientList-item {
    display: block;
}
.IngredientList-item > .IngredientList {
    margin-left: var(--space-md);
}
.IngredientList-groupTitle {
    cursor: pointer;
    user-select: none;
}
.IngredientList-groupTitle::before {
    content: '▸';
    font-family: monospace;
    white-space: pre;
}
.IngredientList-groupTitle.is-open::before {
    content: '▾';
}
.IngredientList-item + .IngredientList-item {
    border-top: none;
}
.IngredientList-groupTitle:hover, 
.IngredientList-ingredientTitle:hover {
    background: var(--color);
}
</style>