<template>
    <router-view 
        :key="$route.path"
        :api-url="apiUrl" />
</template>

<script>
export default {
    computed: {
        apiUrl() {
            return (this.$route.query['x-craft-live-preview'])
                ? `${process.env.VUE_APP_API_URL}?token=${this.$route.query['token']}`
                : process.env.VUE_APP_API_URL
        }
    }
};
</script>

<style>
    @import "@/assets/css/main.scss";
    #App {
        max-width: 1400px;
    }
</style>
