<template>

    <!-- Ingredient Entry View -->
    <IngredientEntryView 
        v-if="viewType == 'entry'" />

    <!-- Ingredient Directory View -->
    <IngredientDirectoryView 
        v-else-if="viewType == 'directory'" />

</template>

<script>
import IngredientEntryView from '@/views/ingredients/_entry.vue';
import IngredientDirectoryView from '@/views/ingredients/_directory.vue';
import { mapState, mapActions } from 'pinia';
import { useIngredientsStore } from '@/stores/ingredients';

export default {
    name: 'IngredientsView',
    components: {
        IngredientEntryView,
        IngredientDirectoryView,
    },
    data() {
        return {
            viewType: null,
        };
    },
    computed: {

        /**
         * Store Getters
         */
        ...mapState(useIngredientsStore, [
            'getIngredientTypeBySlug',
        ]),
    },
    async created() { 
        await this.fetchState();        
        this.setViewType();
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useIngredientsStore, [
            'fetchState',
        ]),

        /**
         * Set child view type based on route
         */
        setViewType() {
            const typeHandle = (this.$route.params.slug)
                ? this.getIngredientTypeBySlug(this.$route.params.slug)
                : null;
            this.viewType = (this.$route.params.slug)
                ? typeHandle == 'ingredient'
                    ? 'entry'
                    : 'directory'
                : 'directory';
        },
    },
};
</script>