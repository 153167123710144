<template>
    <nav class="MainNav">
        <ul class="MainNav-list">
            <li class="MainNav-item MainNav-item--home">
                <router-link 
                    to="/"
                    class="MainNav-link">
                    № Menu
                </router-link>
            </li>
            <li class="MainNav-item MainNav-item--menus u-border--menu">
                <router-link 
                    to="/menus"
                    class="MainNav-link">
                    Menus
                </router-link>
            </li>
            <li class="MainNav-item MainNav-item--recipes u-border--recipe">
                <router-link 
                    to="/recipes"
                    class="MainNav-link">
                    Recipes
                </router-link>
            </li>
            <li class="MainNav-item MainNav-item--ingredients u-border--ingredient">
                <router-link 
                    to="/ingredients"
                    class="MainNav-link">
                    Ingredients
                </router-link>
            </li>
        </ul>
        <div 
            v-if="$route.name !== 'search'"
            class="MainNav-search">
            <SearchBar 
                :is-mini="true" />
        </div>
    </nav>
</template>

<script>
import SearchBar from './SearchBar.vue';
export default {
    name: "MainNav",
    components: { 
        SearchBar
    },
}
</script>

<style>
.MainNav {
    padding: 0 var(--space-sm);
    margin: var(--space-sm) 0;
    display: flex;
    gap: var(--space-sm);
}
.MainNav-list {
    display: flex;
    gap: var(--space-sm);
    align-items: flex-start;
}
.MainNav-item--home {
    background-image: url(@/assets/img/pattern-bg-fine.png);
    padding: var(--border-width);
    image-rendering: crisp-edges;
}
.MainNav-link {
    padding: 0 var(--space-xs);
}
.MainNav-search {
    flex: 1;
}
</style>