<template>
    <section class="BodyText">
        <div 
            v-for="block in blocks"
            :key="block.id"
            class="BodyText-text"
            v-html="block.text" />
    </section>
</template>

<script>
export default {
    name: "BodyText",
    props: {
        blocks: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style>
.BodyText-text {
    margin: var(--space-sm) 0;
}
.BodyText a {
    text-decoration: underline;
}
.BodyText p + p {
    text-indent: 4em;
}
</style>