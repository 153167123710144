<template>
    <nav class="IngredientNav">
        <ul class="IngredientNav-list">

            <!-- Parent Group -->
            <li 
                v-for="ancestor in ancestors" 
                :key="ancestor.id"
                class="IngredientNav-item">

                <!-- Parent Group Link  -->
                <router-link 
                    :to="(`/ingredients/${ ancestor.slug }`)"
                    class="IngredientNav-link u-border--ingredient">
                    {{ ancestor.title }}
                </router-link>
                
            </li>

            <!-- Current Ingredient -->
            <li class="IngredientNav-item">
                {{ title }}
            </li>

        </ul>
    </nav>
</template>

<script>
export default {
    name: "IngredientNav",
    props: {
        title: {
            type: String,
            default: () => (''),
        },
        ancestors: {
            type: Array,
            default: () => ([]),
        }
    },
}
</script>

<style>
.IngredientNav {
    margin: var(--space-sm) 0;
}
.IngredientNav-item {
    display: inline-block;
    margin-bottom: var(--space-xs);
}
.IngredientNav-item:not(:last-of-type)::after {
    content: ' > ';
    white-space: pre;
}
.IngredientNav-link {
    display: inline-block;
}
</style>