<template>
    <PageComponent 
        :header="header">

        <!-- Search Bar -->
        <SearchBar />

        <!-- Recipe List -->
        <RecipeList 
            :entries="entries"
            :has-filters="true" />

    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import RecipeList from '@/components/recipe/RecipeList.vue';
import SearchBar from '@/components/SearchBar.vue';
import { mapState } from 'pinia'
import { useSearchStore } from '@/stores/search'

export default {
    name: "SearchView",
    components: {
        PageComponent,
        RecipeList,
        SearchBar,
    },
    data() {
        return {
            entries: [],
        }
    },
    computed: {

        /**
         * Store Getters
         */
        ...mapState(useSearchStore, [
            'getQueryTagsFromQuery',
            'getResultsFromSearchString',
        ]),

        /**
         * Header
         * @returns {string}
         */
        header() {
            return (this.entries.length)
                ? `${ this.entries.length } Results`
                : 'Search';
        }
    },
    watch: {
        '$route.params': 'handleQuery',
    },
    created() {
        this.handleQuery();
    },
    methods: {

        /**
         * Handle Query
         */
        handleQuery() {
            const queryTags = this.getQueryTagsFromQuery(this.$route.query);
            const searchString = queryTags
                .map(queryTag => `ingredients:'${ queryTag.title }'`)
                .join(' ');
            console.log(searchString);
            if (queryTags.length) {
                this.fetchData(searchString);
            } else {
                this.entries = [];
            }
        },

        /**
         * FetchData
         * @param {string} searchString 
         */
        async fetchData(searchString) {
            const entries = await this.getResultsFromSearchString(searchString);
            this.entries = entries;
        },
    }
}
</script>
