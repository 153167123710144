<template>
    <dl class="IngredientMeta">

        <!-- Other Names -->
        <MetaItem 
            v-if="metadata.otherNames && metadata.otherNames.length" 
            title="Other Names">
            <span 
                v-for="(otherName, i) in metadata.otherNames"
                :key="i">
                {{ otherName.name }}{{ formatSeparator(i, metadata.otherNames) }}
            </span>
        </MetaItem>

        <!-- Related Ingredients -->
        <MetaItem 
            v-if="metadata.relatedIngredients && metadata.relatedIngredients.length" 
            title="Common Alternatives">
            <template 
                v-for="(ingredient, i) in metadata.relatedIngredients"
                :key="i">
                <router-link 
                    :to="`/ingredients/${ingredient.slug}`">
                    {{ ingredient.title }}
                </router-link>
                <span>
                    {{ formatSeparator(i, metadata.relatedIngredients) }}
                </span>
            </template>
        </MetaItem>

        <!-- Ratio -->
        <MetaItem 
            v-if="metadata.ratio" 
            title="Ratio">
            <span>
                {{ metadata.ratio }}
            </span>
        </MetaItem>

        <!-- Affiliates -->
        <MetaItem 
            v-if="metadata.affiliates && metadata.affiliates.length" 
            title="Brands We Like">
            <span 
                v-for="(affiliate, i) in metadata.affiliates"
                :key="i">
                <a 
                    :href="affiliate.affiliateUrl" 
                    target="blank">
                    {{ affiliate.affiliateName }}
                </a>
                <span>
                    {{ formatSeparator(i, metadata.affiliates) }}
                </span>
            </span>
        </MetaItem>
    </dl>
</template>

<script>
import MetaItem from '@/components/MetaItem.vue'

export default {
    name: "IngredientMeta",
    components: {
        MetaItem,
    },
    props: {
        metadata: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {

        /**
         * Render a comma, if neccesary
         * @param {number} i 
         * @param {Array} array 
         */
        formatSeparator(i, array) {
            return (i < array.length - 1) ? ' , ' : ''
        }
    }
}
</script>

<style>
.IngredientMeta {
    margin: var(--space-sm) 0;
}
</style>