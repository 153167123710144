<template>
    <div class="Page">
        
        <!-- Main Nav -->
        <MainNav :api-url="apiUrl" />

        <!-- Page Header -->
        <header 
            class="Page-header"
            :class="{
                'u-border--ingredient' : headerType == 'ingredient',
                'u-border--recipe' : headerType == 'recipe',
                'u-border--menu' : headerType == 'menu',
            }">
            <PatternComponent v-if="headerType == 'home'" />
            <h1 v-html="nl2br(header)" />
        </header>

        <!-- Page Body -->
        <section class="Page-body">
            <slot />
        </section>

        <!-- Partner -->
        <section class="Page-partnerContainer">
            <AdComponent :api-url="apiUrl" />
        </section>

        <!-- Page Footer -->
        <footer class="PageFooter">
            <nav class="PageFooter-nav">
                <ul class="PageFooter-navList">
                    <li class="PageFooter-navItem">
                        <a 
                            class="PageFooter-link"
                            href="#">
                            Back To Top
                        </a>
                    </li>
                    <li class="PageFooter-navItem">
                        <a 
                            class="PageFooter-link"
                            href="/about">
                            About
                        </a>
                    </li>
                    <li class="PageFooter-navItem">
                        <a 
                            class="PageFooter-link"
                            href="#">
                            Provisions
                        </a>
                    </li>
                    <li class="PageFooter-navItem">
                        <a 
                            class="PageFooter-link"
                            href="#">
                            Follow
                        </a>
                    </li>
                </ul>
            </nav>
        </footer>
    </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'
import PatternComponent from '@/components/PatternComponent.vue'
import AdComponent from '@/components/AdComponent.vue'

export default {
    name: "PageComponent",
    components: {
        MainNav,
        PatternComponent,
        AdComponent,
    },
    props: {
        header: {
            type: String,
            default: () => "",
        },
        headerType: {
            type: String,
            default: () => null,
        },
        apiUrl: {
            type: String,
            default: () => (process.env.VUE_APP_API_URL),
        },
    },
    methods: {

        /**
         * Converts \n to <br>
         * @param {string} str 
         * @return {string}
         */
        nl2br(str) {
            return (str)
                ? str.replace(/(?:\r\n|\r|\n)/g, "<br>")
                : null
        },
    },
};
</script>

<style>
.Page-header {
    padding: var(--space-lg) var(--space-md);
    margin: var(--space-sm);
    aspect-ratio: 3/1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}
.Page-body {
    padding: 0 var(--space-sm);
    margin: var(--space-sm) 0;
}
.PageFooter {
    margin-top: var(--space-sm);
}
.PageFooter-navList {
    padding: var(--space-sm);
    display: flex;
    gap: var(--space-sm);
}
.Page-partnerContainer {
    padding: var(--space-sm);
}
</style>