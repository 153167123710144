<template>
    <ul class="MenuList">
        <li 
            v-for="(entry, i) in entries"
            :key="entry.id"
            class="MenuList-item u-border--menu"
            :style="`--color: ${ entry.color }`">
            <router-link 
                :to="`/menus/${entry.slug}`"
                class="MenuList-link">
                Menu № {{ entryCount - i }}
                <br>{{ entry.title }}
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'MenuList',
    props: {
        entryCount: {
            type: Number,
            default: () => (0),
        },
        entries: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>

<style>
.MenuList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-sm);
}
.MenuList-item {
    aspect-ratio: 3 / 2;
}
.MenuList-item:hover {
    background-color: var(--color);
}
.MenuList-link {
    padding: var(--space-md);
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}
</style>