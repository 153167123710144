import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import { useIngredientsStore } from './ingredients'
import axios from "axios"

export const useRecipesStore = defineStore('recipes', {
    state: () => ({
        entries: [],
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },

        /**
         * Get Recipe By Slug
         * Performs GraphQL query and returns the object
         * @param {string} slug
         * @returns {Object}
         */
        getRecipeBySlug() {
            return async (slug) => {
                const entryResponse = await axios.post(this.apiUrl, {
                    query: `{
                        entry(section: "recipes", slug: "${ slug }") {
                            title
                            slug
                            id
                            ... on recipes_default_Entry {
                                recipeAuthor {
                                    name
                                    url
                                }
                                recipeSource {
                                    title
                                    url
                                }
                                recipeAdaptedBy {
                                    name
                                    url
                                }
                                prepTime {
                                    hours
                                    minutes
                                    note
                                }
                                yields
                                cookingMethod {
                                    id
                                    title
                                }
                                cuisines {
                                    id
                                    title
                                }
                                dietaryConsiderations {
                                    id
                                    title
                                }
                                mealTypes {
                                    id
                                    title
                                }
                                specialEquipment {
                                    id
                                    title
                                }
                                summary
                                ingredients {
                                    ... on ingredients_ingredient_BlockType {
                                        entry {
                                            title
                                            slug
                                            id
                                        }
                                        typeHandle
                                        displayName
                                        quantity
                                        unit
                                        customUnit
                                        optional
                                    }
                                    ... on ingredients_separator_BlockType {
                                        typeHandle
                                        separatorText
                                    }
                                }
                                instructions {
                                    ... on instructions_step_BlockType {
                                        text
                                        typeHandle
                                        id
                                    }
                                    ... on instructions_note_BlockType {
                                        text
                                        typeHandle
                                        id
                                    }
                                }
                                relatedRecipes {
                                    title
                                    slug
                                    id
                                    ... on recipes_default_Entry {
                                        recipeAuthor {
                                            name
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }`,
                });
                const entry = entryResponse.data.data.entry;
                const relatedEntriesResponse = await axios.post(this.apiUrl, {
                    query: `{
                        entries(section: "menus", relatedToEntries: [{id: ${ entry.id }}]) {
                            title
                            slug
                            id
                            url
                        }
                    }`,
                })
                const relatedMenus = relatedEntriesResponse.data.data.entries;
                return {
                    ...entry,
                    recipeSource: (entry.recipeSource) ? entry.recipeSource[0] : null,
                    recipeAdaptedBy: (entry.recipeAdaptedBy) ? entry.recipeAdaptedBy[0] : null,
                    relatedMenus: relatedMenus,
                }
            }
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            if (!this.entries.length) {
                const ingredients = useIngredientsStore();
                await ingredients.fetchState();
                const response = await axios.post(this.apiUrl, {
                    query: `{
                        entries (section: "recipes") {
                            title
                            slug
                            id
                            ... on recipes_default_Entry {
                                recipeAuthor {
                                    name
                                    url
                                }
                                yields
                                cookingMethod {
                                    id
                                    title
                                }
                                cuisines {
                                    id
                                    title
                                }
                                mealTypes {
                                    id
                                    title
                                }
                                dietaryConsiderations {
                                    id
                                    title
                                }
                                specialEquipment {
                                    id
                                    title
                                }
                                ingredients {
                                    ... on ingredients_ingredient_BlockType {
                                        entry {
                                            title
                                            slug
                                            id
                                        }
                                    }
                                }
                            }
                        }
                    }`,
                });
                const entries = response.data.data.entries
                    .map((entry) => {
                        const firstIngredient = entry.ingredients?.[0].entry?.[0];
                        const firstIngredientColor = ingredients.entriesFlattened.find((entry) => entry.id == firstIngredient?.id)?.color;
                        return {
                            ...entry,
                            color: firstIngredientColor,
                        }
                    })
                    .sort((a,b) => a.title > b.title);
                this.entries = entries;
            }
        },
    },
});
