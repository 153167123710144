<template>
    <div 
        class="SearchBar"
        :class="{
            'SearchBar--mini': isMini
        }">
        <div class="SearchBar-form">

            <!-- Active Tag -->
            <button 
                v-for="activeTag in activeTags"
                :key="activeTag.id"
                class="SearchBar-activeTag u-border--ingredient"
                @click="deactivateTag(activeTag)">
                {{ activeTag.title }}
            </button>

            <!-- Input Containers -->
            <div 
                v-if="inputIsVisible"
                class="SearchBar-inputContainer u-border--button">

                <!-- Search Input -->
                <input
                    v-model="searchInput"
                    class="SearchBar-input" 
                    type="text"
                    placeholder="Search by ingredient"
                    @input="handleInput">

                <!-- Tag List -->
                <ul 
                    v-if="filteredTags.length"
                    class="SearchBar-tagList">

                    <!-- Tag -->
                    <li 
                        v-for="tag in filteredTags"
                        :key="tag.id"
                        class="SearchBar-tag"
                        @click="activateTag(tag)">
                        {{ tag.title }}
                    </li>

                </ul>
            </div>

            <!-- Submit Button -->
            <button
                v-if="activeTags.length"
                class="SearchBar-submit u-border--button"
                @click="performSearch()">
                Go
            </button>

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useSearchStore } from '@/stores/search'

export default {
    name: "SearchBar",
    props: {
        isMini: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        searchInput: '',
        filteredTags: [],
        activeTags: [],
    }),
    computed: {

        /**
         * State
         */
        ...mapState(useSearchStore, [
            'availableTags',
        ]),

        /**
         * Store Getters
         */
        ...mapState(useSearchStore, [
            'getQueryTagsFromQuery',
        ]),

        /**
         * Test weeather input is visible 
         * @returns {boolean}
         */
        inputIsVisible() {
            return this.activeTags < 1 || !this.isMini;
        },
    },
    async created() {
        await this.fetchState();
        this.handleQuery();
    },
    methods: {

        /**
         * Store Actions
         */
         ...mapActions(useSearchStore, [
            'fetchState'
        ]),

        /**
         * Handle Query
         */
        handleQuery() {
            const queryTags = this.getQueryTagsFromQuery(this.$route.query);
            this.activeTags = this.validateTags(queryTags);
            if (queryTags.length && queryTags !== this.activeTags) {
                this.performSearch();
            }
        },

        /**
         * ValidateTags
         * @param {{ title: string, id: number }[]} tags
         * @returns {{ title: string, id: number }[]}
         */
         validateTags(tags) {
            const validatedTags = tags.filter((tag) => {
                return this.availableTags.some(availableTag => {
                    return availableTag.id == tag.id 
                        && availableTag.title == tag.title;
                });
            });
            return validatedTags;
        },

        /**
         * Perform Search
         */
         performSearch() {
            const searchParams = this.activeTags.map(tag => new URLSearchParams(tag)).join('&');
            this.$router.push(`/search?${ searchParams.toString() }`);
        },        

        /**
         * Handle Input
         */
        handleInput() {
            this.filterTags();
        },

        /**
         * Filter Tags
         */
        filterTags() {
            this.filteredTags = this.availableTags.filter((tag) => {
                const tagTitle = tag.title.toLowerCase();
                const searchInput = this.searchInput.toLowerCase();
                const tagIsActive = this.activeTags.every(activeTag => activeTag.id !== tag.id);
                const tagContainsInput = tagTitle.includes(searchInput);
                const wordStartsWithInput = tagTitle.indexOf(searchInput) == 0 || tagTitle[tagTitle.indexOf(searchInput) - 1] == ' ';
                return searchInput.length > 0
                    && tagIsActive
                    && tagContainsInput
                    && wordStartsWithInput;
            });
            this.filteredTags = this.filteredTags.slice(0, 10);
        },

        /**
         * Activate Tag
         * @param {{ title: string, id: number }} tag
         */
        activateTag(tag) {
            this.activeTags.push(tag);
            this.searchInput = '';
            this.filteredTags = [];
        },

        /**
         * Deactivate Tag
         * @param {{ title: string, id: number }} tag
         */
        deactivateTag(tag) {
            this.activeTags = this.activeTags.filter((activeTag) => {
                return activeTag.id !== tag.id;
            });
            if (!this.isMini) {
                this.performSearch();
            }
        },
    },
};
</script>

<style>
.SearchBar {
    display: flex;
}
.SearchBar-form {
    flex: 1;
    display: flex;
    gap: var(--space-sm);
    align-items: flex-start;
}
.SearchBar-inputContainer {
    flex: 1;
    padding: 0 var(--space-xs);
}
.SearchBar-input {
    width: 100%;
}
.SearchBar-input::placeholder {
    color: var(--color-light-grey);
}
.SearchBar-tag {
    cursor: pointer;
}
.SearchBar-activeTag {
    text-align: left;
    padding: 0 var(--space-xs);
    cursor: pointer;
}
.SearchBar-activeTag::before {
    content: '× '
}
.SearchBar--mini .SearchBar-activeTag {
    flex: 1;
}
.SearchBar-submit {
    padding: 0 var(--space-xs);
    cursor: pointer;
}
</style>