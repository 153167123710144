<template>
    <PageComponent 
        header="Each menu contains a collection of recipes arranged by a guest contributor (and occasionally by № Menu, too)">

        <!-- Recipe List -->
        <MenuList 
            :entries="entries" 
            :entry-count="entryCount" />
            
    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue'
import MenuList from '@/components/menu/MenuList.vue'
import { mapState, mapActions } from 'pinia'
import { useMenusStore } from '@/stores/menus'

export default {
    name: 'MenusView',
    components: {
        PageComponent,
        MenuList,
    },
    computed: {

        /**
         * State
         */
        ...mapState(useMenusStore, [
            'entryCount', 
            'entries',
        ]),
    },
    async created() {
        await this.fetchState();
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useMenusStore, [
            'fetchState',
        ]),
    },
};
</script>

<style>

</style>