<template>
    <div class="MetaItem">
        <dt class="MetaItem-title">
            {{ title }}:
        </dt>
        <dd class="MetaItem-definition">
            <slot />
        </dd>
    </div>
</template>

<script>
export default {
    name: "MetaItem",
    props: {
        title: {
            type: String,
            default: () => (''),
        },
    },
}
</script>

<style>
.MetaItem-title, .MetaItem-definition {
    display: inline;
}
.MetaItem a {
    text-decoration: underline;
}
</style>