<template>
    <dl class="RecipeInstructions">
        <template
            v-for="instruction in instructions"
            :key="instruction.id">
            <dt 
                class="RecipeInstructions-title"
                :class="{
                    'RecipeInstructions-title--step': instruction.typeHandle == 'step',
                    'RecipeInstructions-title--note': instruction.typeHandle == 'note',
                }" />
            <dd 
                class="RecipeInstructions-text" 
                v-html="instruction.text" />
        </template>
    </dl>
</template>

<script>
export default {
    name: 'RecipeInstructions',
    props: {
        instructions: {
            type: Array,
            default: () => ([]),
        }
    },
}
</script>

<style>
.RecipeInstructions {
    counter-reset: step;
}
.RecipeInstructions-title {
    margin-bottom: var(--space-sm);
}
.RecipeInstructions-title--step {
    counter-increment: step;
}
.RecipeInstructions-title--step::before {
    content: 'Step ' counter(step);
}
.RecipeInstructions-title--note::before {
    content: 'Note';
}
.RecipeInstructions p {
    margin: var(--space-sm) 0;
}
.RecipeInstructions p:first-child {
    margin-top: 0;
}
</style>