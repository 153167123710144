<template>
    <PageComponent 
        :header="title"
        :header-type="'menu'">

        <!-- Recipes -->
        <PageSection 
            v-if="relatedRecipes.length"
            :title="`${ guestName.firstName }'s Recipes`">
            <RecipeList 
                :entries="relatedRecipes" />
        </PageSection>

        <!-- Body -->
        <PageSection 
            v-if="bodyChunked.length"
            :title="`${ guestName.firstName }'s Conversation`">
            <template 
                v-for="(chunk, i) in bodyChunked"
                :key="i">
                <BodyText 
                    v-if="chunk.typeHandle == 'bodyText'"
                    :blocks="chunk.blocks" />
                <InterviewText 
                    v-if="chunk.typeHandle == 'interviewText'"
                    :blocks="chunk.blocks" />
            </template>
        </PageSection>

    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue'
import PageSection from '@/components/PageSection.vue'
import BodyText from '@/components/BodyText.vue'
import InterviewText from '@/components/InterviewText.vue'
import RecipeList from '@/components/recipe/RecipeList.vue'
import { mapState } from 'pinia'
import { useMenusStore } from '@/stores/menus'

export default {
    name: 'MenuView',
    components: {
        PageComponent,
        PageSection,
        BodyText,
        InterviewText,
        RecipeList,
    },
    data() {
        return {
            title: '',
            relatedRecipes: [],
            guestName: {
                firstName: '',
                lastName: '',
            },
            body: [],
        }
    },
    computed: {

        /**
         * Store Getters
         */
         ...mapState(useMenusStore, [
            'getMenuBySlug',
        ]),

        /**
         * Body blocks separated into sections
         * @returns {Array}
         */
        bodyChunked() {
            const chunks = [];
            if (this.body.length) {
                chunks[0] = {
                    typeHandle: this.body[0].typeHandle,
                    blocks: [this.body[0]]
                };
                this.body.reduce((a,b) => {
                    if (a.typeHandle == b.typeHandle) {
                        chunks[chunks.length-1].blocks.push(b);
                    } else {
                        chunks.push({
                            typeHandle: b.typeHandle,
                            blocks: [b],
                        });
                    }
                    return b;
                });
            }
            return chunks;
        },        
    },
    async created() {
        await this.fetchData();
    },
    methods: {

        /**
         * Fetch entry data
         */
        async fetchData() {
            const menuData = await this.getMenuBySlug(this.$route.params.slug)
            Object.assign(this, menuData);
        }
    }
}
</script>

<style>

</style>