<template>
    <div class="Pattern">
        <div 
            class="Pattern-layer Pattern-layer--fine"
            :style="`
                mask-image: url(/assets/img/pattern-mask.svg#${ activePatternMap[0] });
                -webkit-mask-image: url(/assets/img/pattern-mask.svg#${ activePatternMap[0] });
            `" />
        <div 
            class="Pattern-layer Pattern-layer--coarse"
            :style="`
                mask-image: url(/assets/img/pattern-mask.svg#${ activePatternMap[1] });
                -webkit-mask-image: url(/assets/img/pattern-mask.svg#${ activePatternMap[1] });
            `" />
    </div>
</template>

<script>

export default {
    name: 'PatternComponent',
    data() {
        return {
            patternMapLookup: [['m1','m0'],['m2','m0'],['m3','m0'],['m4','m0'],['m5','m0'],['m6','m0'],['m7','m0'],['m8','m0'],['m9','m0'],['m10','m0'],['m11','m0'],['m12','m0'],['m13','m0'],['m14','m0'],['m15','m0'],['m16','m0'],['m17','m0'],['m18','m0'],['m19','m0'],['m20','m0'],['m21','m0'],['m22','m0'],['m23','m0'],['m24','m0'],['m25','m0'],['m26','m0'],['m27','m0'],['m28','m0'],['m29','m0'],['m30','m0'],['m31','m0'],['m32','m0'],['m33','m0'],['m34','m0'],['m35','m0'],['m36','m0'],['m37','m0'],['m38','m0'],['m39','m0'],['m9','m41'],['m10','m42'],['m11','m43'],['m12','m44'],['m13','m45'],['m14','m46'],['m15','m47'],['m16','m48'],['m17','m49'],['m18','m50'],['m19','m51'],['m20','m52'],['m21','m53'],['m23','m55'],['m22','m54'],['m24','m56']],
            activePatternMap: null,
        }
    },
    created() {
        this.setPattern();
    },
    methods: {
        /**
         * Set Pattern
         */
        setPattern() {
            const randomIndex = Math.floor(Math.random() * this.patternMapLookup.length);
            this.activePatternMap = this.patternMapLookup[randomIndex];
        }
    }
}
</script>

<style>
.Header {
    aspect-ratio: 2/1;
    background: snow;
    position: relative;
}

.Pattern,
.Pattern-layer {
    position: absolute;
    top: 0; right: 0;
    bottom: 0; left: 0;
    image-rendering: crisp-edges;
    mask-size: 100% 100%;
}

.Pattern-layer--fine {
    background-image: url(@/assets/img/pattern-bg-fine.png);
    mask-image: url(@/assets/img/pattern-mask.svg#m1);
}

.Pattern-layer--coarse {
    background-image: url(@/assets/img/pattern-bg-coarse.png);
    mask-image: url(@/assets/img/pattern-mask.svg#m0);
}
</style>