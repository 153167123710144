import { defineStore } from 'pinia'
import { useGlobalStore } from './global'
import { useRecipesStore } from './recipes'
import axios from "axios"

export const useHomeStore = defineStore('home', {
    state: () => ({
        header: '',
        entryCount: 0,
        entries: [],
    }),
    getters: {

        /**
         * API URL
         * @returns {string}
         */
        apiUrl() {
            const global = useGlobalStore();
            return global.apiUrl;
        },
    },
    actions: {

        /**
         * Fetch State
         * Performs GraphQL query and updates the state
         */
        async fetchState() {
            const response = await axios.post(this.apiUrl, {
                query: `{
                    entry (section: "home") {
                        title
                        ... on home_home_Entry {
                            header
                        }
                    }
                    entryCount(section: "menus")
                    entries (section: "menus") {
                        title
                        slug
                        id
                        ... on menus_default_Entry {
                            relatedRecipes {
                                title
                                slug
                                id
                                ... on recipes_default_Entry {
                                    recipeAuthor {
                                        name
                                        url
                                    }
                                    ingredients {
                                        ... on ingredients_ingredient_BlockType {
                                            entry {
                                                title
                                                slug
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`,
            });
            const entry = response.data.data.entry;
            const entryCount = response.data.data.entryCount;
            const recipes = useRecipesStore();
            await recipes.fetchState();
            const entries = response.data.data.entries.map((entry) => {
                entry.relatedRecipes = entry.relatedRecipes
                    .map((relatedRecipesEntry) => {
                        return recipes.entries.find((e) => relatedRecipesEntry.id == e.id);
                    })
                return entry;
            });
            this.header = entry.header;
            this.entryCount = entryCount;
            this.entries = entries;
        }
    }
})
