<template>
    <PageComponent 
        header="Every recipe published to-date">
        
        <!-- Recipe List -->
        <RecipeList 
            :entries="entries" />

    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import RecipeList from '@/components/recipe/RecipeList.vue';
import { mapState, mapActions } from 'pinia';
import { useRecipesStore } from '@/stores/recipes';

export default {
    name: 'RecipesView',
    components: {
        PageComponent,
        RecipeList,
    },
    computed: {

        /**
         * State
         */
        ...mapState(useRecipesStore, [
            'entries',
        ]),
    },
    async created() {
        await this.fetchState();
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useRecipesStore, [
            'fetchState'
        ]),
    },
};
</script>