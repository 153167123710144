import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import SearchView from '../views/Search.vue'
import IngredientsView from '../views/ingredients/index.vue'
import MenusView from '../views/menus/index.vue'
import MenuView from '../views/menus/_entry.vue'
import RecipesView from '../views/recipes/index.vue'
import RecipeView from '../views/recipes/_entry.vue'
import AboutView from '../views/About.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/ingredients',
        name: 'ingredients',
        component: IngredientsView,
    },
    {
        path: '/ingredients/:slug',
        name: 'ingredient',
        component: IngredientsView,
    },
    {
        path: '/menus',
        name: 'menus',
        component: MenusView,
    },
    {
        path: '/menus/:slug',
        name: 'menu',
        component: MenuView,
    },
    {
        path: '/recipes',
        name: 'recipes',
        component: RecipesView,
    },
    {
        path: '/recipes/:slug',
        name: 'recipe',
        component: RecipeView,
    },
    {
        path: '/search',
        name: 'search',
        component: SearchView,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior (to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
            };
        } else {
            return { x: 0, y: 0 };
        }
    },    
    routes
})

export default router
