<template>
    <PageComponent 
        :header="header"
        header-type="about">

        <section class="BodyText">
            <div
                class="BodyText-text"
                v-html="bodyText" />
        </section>

    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import { mapState, mapActions } from 'pinia';
import { useAboutStore } from '@/stores/about';

export default {
    name: "AboutView",
    components: {
        PageComponent,
    },
    computed: {

        /**
         * State
         */
        ...mapState(useAboutStore, [
            'header',
            'bodyText',
        ]),
    },
    created() {
        this.fetchState()
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useAboutStore, [
            'fetchState',
        ]),
    },
};
</script>
