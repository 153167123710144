<template>
    <PageComponent 
        :header="header"
        header-type="home">

        <!-- Featured List -->
        <FeaturedList 
            :entries="entries"
            :entry-count="entryCount" />

    </PageComponent>
</template>

<script>
import PageComponent from '@/components/PageComponent.vue';
import FeaturedList from '@/components/FeaturedList.vue';
import { mapState, mapActions } from 'pinia';
import { useHomeStore } from '@/stores/home';

export default {
    name: "HomeView",
    components: {
        PageComponent,
        FeaturedList,
    },
    computed: {

        /**
         * State
         */
        ...mapState(useHomeStore, [
            'header',
            'entryCount',
            'entries',
        ]),
    },
    created() {
        this.fetchState();
    },
    methods: {

        /**
         * Store Actions
         */
        ...mapActions(useHomeStore, [
            'fetchState'
        ]),
    },
};
</script>
