<template>
    <section 
        class="IngredientDescription">
        <p>
            {{ description }}
        </p>
        <p 
            v-if="link">
            <a 
                :href="link"
                target="blank">
                Read more on Wikipedia
            </a>
        </p>
    </section>
</template>

<script>
export default {
    name: "IngredientDescription",
    props: {
        description: {
            type: String,
            default: () => (''),
        },
        link: {
            type: String,
            default: () => (''),
        },
    },
    computed: {

        /**
         * Abbreviated Description
         */
        abbreviatedDescription() {
            return (this.description && this.description.length > 400)
                ? `${ this.description.substr(0, 400) }...`
                : this.description
        }
    }
}
</script>

<style>
.IngredientDescription {
    margin: var(--space-sm) 0;
    white-space: pre-line;
}
.IngredientDescription a {
    text-decoration: underline;
}
</style>